import React, {useRef} from 'react'; //Crée une référence que l'on peut attacher à un élément du DOM
import { Animation } from "react-animate-style";


export const QuoteWidget = (props) => {
    const style = {
        container : {
            display : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%', 
    },
    quote: {
        fontSize: '2.5rem'
    }, 
}

const visible = props.screen.position === 'current';

if(props.quote_content) {
         return (
            <Animation className="quote-widget widget" style={style.container} animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}>
                <h1>{props.quote_content}</h1>
            </Animation>
        );
    }
       
}