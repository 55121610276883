import { useSelector } from "react-redux";
import { useTheme } from "../Themes/themeManager";

export const Error = (props) => {

  const ScreenReducer = useSelector(state => state.ScreenReducer);
  const theme = useTheme();

  const style = {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.colors.error,
      color: theme.colors.text,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: "15%",
    },
    sign: {
      backgroundColor: 'white',
      borderRadius: 500,
      fontSize: 32,
      color: theme.colors.error,
      height: 54,
      width: 54,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 16,
    }
  }

  return (<div className='ScreenManager' style={style.container}>
    <div style={style.sign}>⨯</div>
    <div style={{ fontSize: 76 }}>{ScreenReducer.error.status}</div>
    <h1>Une erreur est survenue</h1>
    <h2>{ScreenReducer.error.serverMessage}</h2>
    <strong>Message d'erreur d'origine :</strong>
    <code>{ScreenReducer.error.message}</code>
    <code>{ScreenReducer.error.navigatorMessage}</code>
  </div >)
}