import { Animation } from "react-animate-style";

export const HorairesWidget = (props) => {
  const style = {
    slide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'strech',
    },
    title: {
      margin: 32,
      fontSize: 64,
      textAlign: 'center',
    },
    day: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
      margin: 16,
      fontSize: 32,
    },
    dayName: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      paddingRight: 16,
    }
  }

  let delayValue = 0
  const delay = () => {
    delayValue += 100
    return delayValue
  }

  const visible = props.screen.position === 'current'
  return (<div className="horaire-widget widget" style={style.slide}><div style={style.container}>
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}><div className="titre" style={style.title}> {props.titre}</div></Animation>}
    {
      ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'].map(
        (day, index) => {
          const wait = delay()
          return props[day] ?
            <div style={style.day} key={index}>
              <Animation animationIn="fadeInLeft" animationOut="bounceOutLeft" isVisible={visible} animationInDelay={wait} animationOutDelay={wait}><div style={style.dayName}>{day}</div></Animation>
              <Animation animationIn="fadeInRight" animationOut="bounceOutRight" isVisible={visible} animationInDelay={wait} animationOutDelay={wait}><div className="dayHours">{props[day]}</div></Animation>
            </div>
            : null
        }
      )}
  </div ></div>)
}