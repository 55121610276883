import * as types from './types'

/**
 * Empty state for initialisation
 */
const initialState = {
  planif: null,
  widgets: {
    background: null,
    current: 0,
    next: 0,
  },
  positions: ['background', 'current', 'next'],
  loading: false,
  error: null,
}

/**
 * Screen Reducer
 * Contain all functions for manipulate graphs datas
 * @param {Object} state
 * @param {Object} action
 * @return {Object} updated state
 */
export default function ScreenReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_START:
      return { ...state, loading: true, error: false }
    case types.LOADING_SUCCESS:
      return { ...state, loading: false, planif: action.payload }
    case types.LOADING_FAIL:
      return { ...state, loading: false, error: action.payload }

    case types.ROTATING_START:
      return { ...state, widgets: action.payload.widgets, positions: action.payload.positions }

    default:
      return state
  }
}