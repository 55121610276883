import lottie from 'lottie-web'
import peopleLottie from './people.json';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useRef } from 'react';

export const People = ({ people, style, isActive, timeout = 0, duree }) => {
  const containerRef = useRef();
  const playerRef = useRef();

  useEffect(() => {
    //Edit people metas
    const newDatas = cloneDeep(peopleLottie);
    for (let i = 0; i < newDatas.layers.length; i++) {
      if (newDatas.layers[i].nm === "fonction pro") {
        newDatas.layers[i].t.d.k[0].s.t = people.titre;
      }
      if (newDatas.layers[i].nm === "fabrice thomas") {
        newDatas.layers[i].t.d.k[0].s.t = people.name;
      }
    }
    for (let i = 0; i < newDatas.assets.length; i++) {
      if (newDatas.assets[i].id === "image_0") {
        newDatas.assets[i].u = people.photo.sizes?.full_screen || people.photo.url;
        newDatas.assets[i].p = '';
      }
    }
    //containerRef.current.innerHTML = '';
    playerRef.current?.destroy()
    playerRef.current = lottie.loadAnimation({
      container: containerRef.current,
      animationData: newDatas,
      loop: false,
      autoplay: false,
    });
  }, [people])

  useEffect(() => {
    if (isActive) {
      console.log("Got to and play", timeout)
      const to = setTimeout(() => { console.log("tick"); playerRef.current.goToAndPlay(0) }, timeout);
      const toexit = setTimeout(() => { console.log("tickOut"); playerRef.current.setDirection(-1); playerRef.current.play() }, (duree * 1000) - 2200 + timeout);
      return () => { clearTimeout(to); clearTimeout(toexit); console.log("=== Clear timesouts ===") };
    } else {
      // playerRef.current.stop();
    }
  }, [isActive, timeout, duree])

  return <div style={style} ref={containerRef}></div>
}