import { contrastedColor } from "../Utils/Colors"
import { Animation } from "react-animate-style";

export const ProductWidget = (props) => {
  const style = {
    slide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'strech',
      maxWidth: '80%',
    },
    imageContainer: {
      maxWidth: '100%',
    },
    image: {
      display: 'block',
      objectFit: 'contain',
      width: 'auto',
      maxWidth: '100%',
    },
    content: {
      maxWidth: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 32,
    },
    title: {
      fontSize: 48,
      textTransform: 'uppercase',
    },
    etiquette: {
      display: 'inline-block',
      fontSize: 16,
      lineHeight: 1,
      fontWeight: 'bold',
      backgroundColor: props.etiquette_background,
      color: contrastedColor(props.etiquette_background),
      borderRadius: 4,
      padding: '4px 8px',
    },
    price: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
      marginBottom: 32,
      fontSize: 32,
    },
    description: {
      fontWeight: 'bold',
      paddingRight: 16,
      fontSize: 24
    }
  }

  const animate = props.screen.position === 'current'

  return (<div className="product-widget widget" style={style.slide}><div style={style.container}>
    {props.image && <Animation animationIn="fadeInLeftBig" animationOut="bounceOutLeft" isVisible={animate} ><div style={style.imageContainer}><img src={props.image.sizes?.large || props.image.url} alt={props.image.alt} style={style.image} /></div></Animation>}
    <div style={style.content}>
      {props.name && <Animation animationIn="fadeInRightBig" animationOut="bounceOutRight" isVisible={animate}><div style={style.title}>{props.name}</div></Animation>}
      {props.etiquette && <Animation animationIn="bounceIn" animationOut="bounceOutRight" isVisible={animate} animationInDelay={1000} animationOutDelay={100}><div style={style.etiquette}> {props.etiquette}</div></Animation>}
      {props.prix && <Animation animationIn="fadeInRightBig" animationOut="bounceOutRight" isVisible={animate} animationInDelay={100} animationOutDelay={200}><div style={style.price}>{props.prix}</div></Animation>}
      {props.description && <Animation animationIn="fadeInRightBig" animationOut="bounceOutRight" isVisible={animate} animationInDelay={200} animationOutDelay={300}><div style={style.description}> {props.description}</div></Animation>}
    </div>
  </div ></div>)
}