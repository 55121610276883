import PropTypes from 'prop-types';
import Widgets from '../Widgets'
import React from 'react';
import { useSelector } from 'react-redux';
import { contrastedColor } from '../Utils/Colors';

const Screen = ({ identifier, position }) => {
  const zIndex = { background: 0, current: 1, next: 2 }
  const opacity = { background: 0, current: 1, next: 0 }

  const state = useSelector(state => state.ScreenReducer)
  const slide = state.planif[state.widgets[position]]

  const style = {
    screen: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: zIndex[position],
      opacity: opacity[position],
      transition: 'opacity 1s ease-in-out',
    },
    background: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
    },
    content: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    }
  }

  if (state.widgets[position] === null || !Widgets[slide.acf_fc_layout]) {
    return null
  }

  if (slide.background) {
    switch (slide.background.type) {
      case 'color':
        style.background.backgroundColor = slide.background.color
        style.content.color = contrastedColor(slide.background.color)
        break
      case 'gradient':
        if (slide.background['gradient-type'] === 'linear') {
          style.background.backgroundImage = `linear-gradient(${slide.background['gradient-rotation']}deg, ${slide.background['gradient-colors'].map(c => c.color).join(', ')})`
        } else {
          style.background.backgroundImage = `radial-gradient(${slide.background['gradient-colors'].map(c => c.color).join(', ')})`
        }
        style.content.color = contrastedColor(slide.background['gradient-colors'][0].color)
        break
      case 'image':
        style.background.backgroundImage = `url(${slide.background.image.sizes.full_screen || slide.background.image.url})`
        style.background.backgroundSize = 'cover'
        style.content.color = '#fff'
        style.content.textShadow = '0 0 8px #000'
        const filters = []
        if (slide.background.effect.findIndex(e => e === 'black') > -1) {
          filters.push(`grayscale(100%)`)
        }
        if (slide.background.effect.findIndex(e => e === 'blur') > -1) {
          filters.push(`blur(8px)`)
        }
        if (slide.background.effect.findIndex(e => e === 'zoom') > -1) {
          style.background.backgroundSize = `100% 100%`
          style.background.backgroundPosition = `center center`
          style.background.backgroundRepeat = `no-repeat`
          style.background.animation = `shrink ${slide.duree}s linear infinite alternate`
        }
        style.background.filter = filters.join(' ')

        break
      default:
    }
  }



  return (<div className="Screen" data-position={position} style={style.screen}><div style={style.background}></div><div style={style.content}>{React.createElement(Widgets[slide.acf_fc_layout], { ...slide, screen: { identifier, position } })}</div></div>)
}

Screen.propTypes = {
  /**
   * Unique Identifier of the screen
   */
  identifier: PropTypes.number.isRequired,

  /**
   * Position of the screen. value could be "background", "background" or "next"
   */
  position: PropTypes.oneOf(["background", "next", "current"]).isRequired
}

export { Screen }