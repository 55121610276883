/**
 * Start Loading
 */
export const LOADING_START = 'LOADING_START_SCREEN'
export const LOADING_SUCCESS = 'LOADING_SUCCESS_SCREEN'
export const LOADING_FAIL = 'LOADING_FAIL_SCREEN'

/**
 * Rotating management
 */
export const ROTATING_START = 'ROTATING_SCREEN'