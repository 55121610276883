import { ImageWidget } from "./ImageWidget"
import { MessageWidget } from "./MessageWidget"
import { YoutubeWidget } from "./YoutubeWidget"
import { HorairesWidget } from "./HorairesWidget"
import { ProductWidget } from "./ProductWidget"
import { PeoplesWidget } from "./PeoplesWidget"
import { MeteoWidget } from "./MeteoWidget"
import { MeteoInternationaleWidget } from "./MetinterWidget"
import { AstroWidget } from "./AstroWidget"
import { QuoteWidget } from "./QuoteWidget"
import { NewsWidget } from "./NewsWidget"

const Widgets = {
  message: MessageWidget,
  image: ImageWidget,
  youtube: YoutubeWidget,
  horaires: HorairesWidget,
  produit: ProductWidget,
  peoples: PeoplesWidget,
  meteo: MeteoWidget,
  meteointer: MeteoInternationaleWidget,
  astro: AstroWidget,
  quote: QuoteWidget,
  news : NewsWidget
}

export default Widgets