import { Provider as ReduxProvider } from 'react-redux'
import { load } from './Redux/Screen/actions'
import { useEffect } from 'react';

import './App.css';
import { ScreenManager } from './Screen/ScreenManager';
import store from './Redux';

function App() {

  useEffect(() => {
    store.dispatch(load(true))
  }, [])

  return (
    <ReduxProvider store={store}>
      <ScreenManager />
    </ReduxProvider>
  );
}

export default App;
