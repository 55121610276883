import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import ScreenReducer from './Screen/reducer';

/**
 * Create a Root reducer by combining all reducers
 */
const RootReducer = combineReducers({
  ScreenReducer
});

/**
 * Initialize the store
 */
const store = createStore(RootReducer, applyMiddleware(thunk));

export default store;