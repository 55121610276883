import { Animation } from "react-animate-style";

export const MeteoWidget = (props) => {
  const style = {
    slide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'strech',
      width: '700px',
      fontFamily: 'Quicksand',
    },
    title: {
      margin: '32px auto',
      fontSize: 64,
    },
    temperature: {
      fontSize: '6rem',
      margin: '-30px 0 0 0',
    },
    descmeteo: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    listMeteo: {
      display: 'flex',
      flexDirection: 'column',
      listStyleType: 'none',
      textAlign: 'center',
      width: '100%',
      margin: '1rem auto',
      padding: '1.5rem',
      borderRadius: '12px',
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    day: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
      margin: 16,
      fontSize: 32,
    },
    dayName: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      paddingRight: 16,
    }
  }

  const urlMeteoIcone = 'http://openweathermap.org/img/wn/'
  const urlMeteoExtension = '@4x.png'

  const visible = props.screen.position === 'current'


  if (props.meteo_body.message === 'city not found') {
    return (<div className="meteo-widget widget" style={style.slide}><div style={style.container}>
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}><div className="titre" style={style.title}> {props.meteo_ville}</div></Animation>}
    <div className="container">{props.meteo_ville ? <Animation animationIn="fadeInDown" animationOut="bounceOutUp"> <div className="location"><h1>{props.meteo_ville}</h1></div></Animation> : null}
    <div className="meteoBody"><p>Erreur : la ville n'existe pas</p></div>
    </div></div></div>) 
  } else {
  return (<div className="meteo-widget widget" style={style.slide}><div style={style.container}>
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}><div className="titre" style={style.title}> {props.meteo_ville}</div></Animation>}
    <div className="container">
      {props.meteo_ville ? <div className="topMeteo">
        <div>
          <h2 style={style.title}>{props.meteo_ville}</h2>
          <h1 style={style.temperature}>{Math.round(props.meteo_body.main.temp)}°C</h1>
          <p style={style.descmeteo}>{props.meteo_body.weather[0].description}</p>
        </div>
        <img src={urlMeteoIcone + props.meteo_body.weather[0].icon + urlMeteoExtension} alt="Icone meteo"/>
        </div> : null}
    {props.meteo_body ? <div><div style={style.listMeteo}>
      <div className="bodyMeteoTop">
        <div><p className="bold">{props.meteo_body.main.feels_like}°C</p><p className="thin">Ressenti</p></div>
        <div><p className="bold">{props.meteo_body.main.humidity}%</p><p className="thin">Humidité</p></div>
        <div><p className="bold">{props.meteo_body.wind.speed}m/s</p><p className="thin">Vitesse du vent</p></div>
      </div>
      </div></div> : null}
    </div>
  </div></div> )
  }
}