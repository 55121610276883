import { Animation } from "react-animate-style";

export const NewsWidget = (props) => {
    const style = {
        container: {
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        title: {
            margin: '20px',
        }
    }

    const visible =props.screen.position === 'current';

    if(props.news_body.status !== 'ok') {
        return (
            <div className="news-widget widget">
                <div style={style.container}>
                    {props.titre && (
                        <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}>
                            <div className="titre" style={style.title}> {props.titre}</div>
                        </Animation>
                    )}
 
                    <div className="container">
                        {props.news_data.article.map((article, index) => (
                            <Animation key={index} animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}>
                            <div className="newsItem" style={style.newsItem}>
                                <div className="newsTitle">{article.title}</div>
                                <div className="newsDescription">{article.description}</div>
                                <div className="newsSource">Source :{article.source}</div>
                            </div>
                            </Animation>
                        ))}
                    </div>
                </div>

            </div>
        )
    }
}