import { Animation } from "react-animate-style";

export const AstroWidget = (props) => {
  const style = {
    slide: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '80%',
      width: '80%',
      fontFamily: 'Quicksand',
    },
    title: {
      margin: '32px auto',
      fontSize: 64,
    },
    topAstro: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridColumnGap: '0px',
      gridRowGap: '10%',
    },
    signeNom: {
      fontSize: '2rem',
    },
    blocastro: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }

  const mood = [
    'http://openweathermap.org/img/wn/10d@4x.png',
    'http://openweathermap.org/img/wn/03d@4x.png',
    'http://openweathermap.org/img/wn/01d@4x.png'
  ]



  const visible = props.screen.position === 'current'

  if (props.astro_body === 'erreur') {
    return (
      <div className="astro-widget widget" style={style.slide}><div style={style.container}>
      <div className="container">
        { <Animation animationIn="fadeInDown" animationOut="bounceOutUp"><div className="location"><h1>Erreur module Astro</h1></div></Animation> }
    <div className="astroBody"><p>Erreur : merci de contacter le webmaster</p></div>
    </div></div></div>) 
  } else {
  return (<div className="astro-widget widget" style={style.slide}><div style={style.container}>
    {props.titre && <Animation animationIn="fadeInDown" animationOut="bounceOutUp" isVisible={visible}><div className="titre" style={style.title}>Votre horoscope</div></Animation>}
      {props.astro_body ? <div className="astroFade" style={style.topAstro}>
        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[0].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[0].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[1].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[1].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[2].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[2].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[3].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[3].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[4].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[4].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[5].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[5].nom}</h2>
        </div>
        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[6].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[6].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[7].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[7].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[8].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[8].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[9].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[9].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[10].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[10].nom}</h2>
        </div>

        <div style={style.blocastro}>
          <img src={mood[props.astro_body.signes[11].global - 1]} alt="icone-mood" />
          <h2 style={style.signeNom}>{props.astro_body.signes[11].nom}</h2>
        </div>
        </div> : null}
  </div></div> )
  }
}