import axios from '../axios'
import * as types from './types'

// Used in rotate and stopRotation to play and pause the rotate timer
let rotateTimer = null

/**
 * ============================================================================
 * ||                                                                        ||
 * || Loading actions                                                        ||
 * ||                                                                        ||
 * ============================================================================
 */

/**
 * Start Loading of planifications
 */
export const load = (firstLoad = false) => (dispatch, state) => {
  dispatch({ type: types.LOADING_START }) // Start loading, used to display "loading" message
  axios.get(`/nsi-livetv/v1/planif`)
    .then(response => {
      console.log(response.data)
      // set planifications in reducer
      dispatch({ type: types.LOADING_SUCCESS, payload: response.data })

      // if first load, immediately start rotating for initialisation.
      // First load is only set to true when called from App.js (on first load of the page)
      if (firstLoad) {
        dispatch(rotate())
      }

      // Refresh planif every 5 minutes (with firstLoad to false to avoid multiple instances of rotate timer)
      setTimeout(() => { dispatch(load()) }, 5 * 60 * 1000)
    })
    .catch(error => {
      dispatch({ type: types.LOADING_FAIL, payload: error })
      // If error, refresh planif in 5 minutes for retry.
      // Firstload as the same value to avoid multiple instances of rotate timer (if error occur on refresh) or correctly run rotate timer on first load.
      setTimeout(() => { dispatch(load(firstLoad)) }, 5 * 60 * 1000)
    })

}

/**
 * Rotate screens to show next screen
 * 
 */
export const rotate = () => (dispatch, state) => {
  // Get values from state
  const widgets = { ...state().ScreenReducer.widgets }
  const planif = state().ScreenReducer.planif
  const positions = [...state().ScreenReducer.positions]

  // Move position array to make 'next' become 'current' and 'current' become 'background'
  positions.unshift(positions.pop())

  // Increment widgets indexes
  widgets.background = widgets.current
  widgets.current = widgets.next
  if (widgets.next + 1 >= planif.length) {
    widgets.next = 0 // Reset to 0 if we reach the end of the planif
  } else {
    widgets.next = widgets.next + 1 // Increment next index
  }

  // Restart the rotation at the end of the time specified for the new current screen
  rotateTimer = setTimeout(() => { dispatch(rotate()) }, parseInt(state().ScreenReducer.planif[widgets.current].duree) * 1000)

  // Dispatch new values
  dispatch({ type: types.ROTATING_START, payload: { widgets, positions } })
}

/**
 * Stop rotate timer for special screens like youtube who need to be rotated manually at the end of the video
 */
export const stopRotation = () => {
  clearTimeout(rotateTimer)
}